import './App.css';
import twitter from "./twitter (2).png"
import discord from "./discord (8).png"
function App() {
  return (<div className="main">
    <div className="App">

   <div className="marquee">
  <div className="track">
    <div className="content">&nbsp;
    COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>
    COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>
    COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>COMMON CHIMPS<span>. </span>
    </div>
  </div>
</div>
<div className="logo">
      <img className="chimp" src="https://media.discordapp.net/attachments/887714666065846272/915714764527763506/gif_monkey.gif" alt=""/>
      <div className="links">
        <div className="">
          <button className="twitter">
            <div>
              <img className="btn__logo"  src={twitter} alt=""/>
            </div>
            <div>
            TWITTER
            </div>
            
            </button>
        </div>
        <div>
        <button className="discord">
            <div>
              <img className="btn__logo"  src={discord} alt=""/>
            </div>
            <div>
            DISCORD
            </div>
            
            </button>
        </div>
      </div>
    </div>



<div id="container">
  <div id="copyright">

  </div>
</div>
</div>
    <div className="marquee2">
    <div className="track2">
      <div className="content">&nbsp;
      COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>
      COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>
      COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>COMING SOON<span>. </span>
      </div>
    </div>
  </div>
  </div>
  );
}

export default App;
